
    h4 {
      font-size: 18px;
    }
    h3 {
      font-size: 28px;
    }
    span {
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
    li {
      font-size: 16px;
      font-weight: 500;
    }
    .mainCard {
      height: 20rem;
    }
    .circle {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      overflow: hidden;
    }
    i {
      color: #072f5f;
    }
    ul {
      padding-left: 17px;
    }
    @media (max-width: 574px) {
      .mTop {
        margin-top: 30px;
      }
      p {
        font-size: 18px;
      }
      .img-fluid {
        min-height: 100px;
      }
    }
    @media (min-width: 992px) {
      /* for banner image */
      .bmTop {
        margin-top: 130px;
      }
    }
  