.portfolio-3-area .single-portfolio .portfolio-content span {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 700;
  }
  
  .portfolio-3-area .single-portfolio .portfolio-content a {
    background: #072f5f;
    border-radius: 6px;
    margin-top: 31px;
  }
  
  .portfolio-3-area .single-portfolio .portfolio-content a h4 {
    color: white;
  }
  
  .testimonial-section2 {
    position: relative;
    padding: 60px 0;
    height: 400px;
    background-color: #002249;
    overflow: hidden;
  }
  
  .testim .dots .dot.active,
  .testim .dots .dot:hover {
    background: #0000;
    border-color: #0000;
  }
  
  .testimonial-section2 .what-we-are-shape-1 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .case-icon {
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 0;
    font-size: 20px;
    background: blue;
    color: #fff;
    padding: 2% 3% 2% 3%;
  }
  
  .dubai-case-icon {
    position: absolute;
    z-index: 999;
    bottom: 0;
    right: 0;
    font-size: 20px;
    background: green;
    color: #fff;
    padding: 2% 3% 2% 3%;
  }
  
  .main-image {
    position: relative;
    left: 1rem;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    height: 550px;
    width: 100vw;
    overflow: hidden;
  }
  
  .clientImg {
    width: 80% !important;
    aspect-ratio: 3/2 !important;
    object-fit: contain !important;
    mix-blend-mode: color-burn !important;
  }
  
  /* dubai */
  /* .portfolio-3-area.custom-portfolio-css .single-portfolio:after {
    content: '';
    display: block;
    width: 0;
    bottom: -2px;
    height: 2px;
    background: #418052;
    transition: width .3s;
    position: absolute;
  } */
  
  /* dubai end */
  .card {
    /* Existing card styles */
  
    /* Add shadow on hover */
    transition: box-shadow 0.3s;
  }
  
  .card:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
  
  /* h1 {
    font-size: 35px !important;
    border-bottom: 3px solid white;
    display: inline-block;
  }
   */
  .banner-content p {
    font-size: 26px !important;
  }
  
  .banner-content li {
    font-size: 20px;
    color: #fff;
  }
  
  h4 {
    font-size: 18px;
    color: #000000;
  }
  
  h3 {
    font-size: 28px;
    color: #000000;
  }
  
  span {
    font-size: 16px !important;
  }
  
  .carousel-inner .card-text {
    color: #000000 !important;
  }
  
  .teamTital {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .trdBannar {
    padding-top: 3rem;
    padding-left: 0.5rem;
  }
  
  .spaceLeft {
    padding-left: 1rem;
  }
  
  .single-banner .banner-overlay {
    display: flex;
    align-items: flex-start !important;
    max-width: 100%;
    height: 675px;
    padding-top: 5rem;
    padding-left: 3rem;
  }
  
  .secondBanner {
    position: relative;
    left: 34.8rem;
    top: 7rem;
  }
  
  .btn:hover {
    cursor: pointer;
  }
  
  .captcha .input-box {
    display: inline;
  }
  
  .shadow-card:hover {
    cursor: pointer;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
  }
  
  .fa-chart-bar {
    transition: transform 0.5s, box-shadow 0.5s;
    -webkit-transition: transform 0.5s, box-shadow 0.5s;
    -moz-transition: transform 0.5s, box-shadow 0.5s;
    -ms-transition: transform 0.5s, box-shadow 0.5s;
    -o-transition: transform 0.5s, box-shadow 0.5s;
  }
  
  .case-card {
    position: relative;
    overflow: hidden;
  }
  
  .case-card img {
    object-fit: cover;
    height: 13rem;
    width: 22rem;
  }
  
  .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      rgba(5, 6, 5, 0) 0%,
      rgba(5, 6, 5, 0.64) 78.42%,
      #050605 100%
    );
  }
  
  .case-card .text {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 1;
    color: #fff;
  }
  
  .case-card .text p {
    font-size: 18px;
    line-height: 1.5;
    color: #fff;
  }
  
  .slide-box {
    display: flex;
    justify-content: space-between;
  }
  
  /* .banner {
    background-size: cover;
    width: 100%;
  } */
  
  /* Style the icon bar links */
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }
  
  .whatsapp {
    background: #55ee5f;
    color: white;
  }
  
  .linkedin {
    background: #007bb5;
    color: white;
  }
  
  .linkedin:hover,
  .whatsapp:hover {
    transform: scale(1.2);
  }
  
  @media (max-width: 768px) {
    .main-image {
      height: 400px; /* Adjust this value for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .main-image {
      height: 300px; /* Adjust this value for even smaller screens */
    }
  }
  @media (min-width: 565px) and (max-width: 599px) {
    .banner-overlay {
      margin-top: 50px;
    }
  }
  
  @media (max-width: 565px) {
    .banner-overlay {
      margin-top: 74px;
    }
  
    .bannertext h1 {
      font-size: larger !important;
    }
    .bannertext li {
      font-size: small !important;
    }
  }
  
  @media (max-width: 380px) {
    .banner-overlay {
      margin-top: 34px;
    }
    .main-image {
      height: 300px; /* Adjust this value for even smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .banner-content .title {
      font-size: 30px;
    }
    .banner-overlay {
      padding-top: 1rem !important;
    }
  
    .banner-content li {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .banner-content .title {
      font-size: 24px;
    }
  
    .banner-content li {
      font-size: 14px;
    }
  }
  
  @media (max-width: 380px) {
    .banner-content .title {
      font-size: 20px;
    }
  
    .banner-content li {
      font-size: 12px;
    }
  }
  /* Continue adding media queries for additional breakpoints */
  
  /* Adjust width based on screen width */
  @media (max-width: 1200px) {
    .main-image {
      width: 1000px; /* Adjust this value for smaller screens */
    }
  }
  
  @media (max-width: 1000px) {
    .main-image {
      width: 800px; /* Adjust this value for even smaller screens */
    }
  }
  
  @media (max-width: 800px) {
    .main-image {
      width: 600px; /* Adjust this value for even smaller screens */
    }
  }
  
  /* Continue adding media queries for additional breakpoints */
  
  /* @media (min-width: 576px) and (max-width: 767.98px) {
    .slide-box img {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .slide-box img {
      -ms-flex: 0 0 33.3333%;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
  
  @media (min-width: 992px) {
    .slide-box img {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  
  @media (max-width: 991px) {
    .contactUsImg{
      display: none;
    }
  }
  
  @media (max-width: 426px) {
    .carousel-item div p{
      font-size: 15px !important;
    }
  
    h2{
      font-size: 1.5rem !important;
    }
  
    h3{
      font-size: 1.3rem !important;
    }
  
    h1{
      font-size: 2.5rem !important;
      padding: 0;
    }
  } */
  
  /* .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 0.5rem;
  } */
  
  /* @media screen and (max-width: 599px) {
    .banner {
      background-position: initial;
    }
  } */
  
  /* @media only screen and (max-width: 984px){
  .single-banner .banner-overlay .banner-content .title {
      font-size: 55px;
      line-height: 80px;
      background-position: initial;
  }} */
  @media (min-width: 280px) and (max-width: 600px) {
    .services-container {
      margin: auto;
    }
    .single-banner .banner-overlay {
      padding-left: 0 !important;
    }
    .trdBannar {
      padding-top: 0;
    }
    .banner-content li {
      padding-top: 15px !important;
      padding-bottom: 15px !important ;
    }
    /* .single-banner{
      margin-bottom: 30px !important;
    } */
  
    .services-card {
      width: 33% !important;
      height: auto;
      display: flex;
    }
    /* .services-card a{
      height: 100%;
    } */
  
    .product-description {
      width: 100% !important;
      margin: auto;
    }
    /* .card{
      width:40% !important;
    } */
    .testimonial-section2 {
      height: auto !important;
    }
    .testimonial-section2 .container .myCarousel .carousel-inner {
      margin: 0 auto !important;
      width: auto !important;
    }
    .testimonial-container {
      margin: 0 auto !important;
      width: auto !important;
    }
    .fa-linkedin-in {
      margin-left: 8px !important;
    }
    /* .services-plans-dark-area .card{
      height: auto !important;
      border-radius: 30px !important;
      padding: 0 !important;
    } */
  }
  
  @media (min-width: 601px) and (max-width: 768px) {
    /* .single-banner .banner-overlay{
      padding-left: 0 !important;
    } */
  
    .services-card {
      width: 46% !important;
      height: auto;
      display: flex;
    }
    /* .services-card a{
      height: 100%;
    } */
  
    .product-description {
      width: 100% !important;
      margin: auto;
    }
    .card {
      width: 40% !important;
    }
    .testimonial-section2 {
      height: auto !important;
    }
    .testimonial-section2 .container .myCarousel .carousel-inner {
      margin: 0 auto !important;
      width: auto !important;
    }
    .testimonial-container {
      margin: 0 auto !important;
      width: auto !important;
    }
    .fa-linkedin-in {
      margin-left: 8px !important;
    }
    .services-plans-dark-area .card {
      height: auto !important;
      border-radius: 30px !important;
      padding: 0 !important;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    /* .single-banner .banner-overlay{
      padding-left: 0 !important;
    } */
  
    /* .services-card{
      width: 56% !important;
      height: auto;
      display: flex;
    }
    .services-card a{
      height: 100%;
    } */
  
    .product-description {
      width: 100% !important;
      margin: auto;
    }
    .card {
      width: 40% !important;
    }
    .testimonial-section2 {
      height: auto !important;
    }
    .testimonial-section2 .container .myCarousel .carousel-inner {
      margin: 0 auto !important;
      width: auto !important;
    }
    .testimonial-container {
      margin: 0 auto !important;
      width: auto !important;
    }
    .fa-linkedin-in {
      margin-left: 8px !important;
    }
  
    .services-plans-dark-area .card {
      height: auto !important;
      border-radius: 30px !important;
      padding: 0 !important;
    }
    .teamTital {
      margin: 0 15% !important;
    }
    .teamTital div {
      margin-left: 26% !important;
    }
    .contact-form-area {
      margin: 0 10% !important;
    }
    .contact-form-area .input-box {
      text-align: right;
      margin-bottom: 3%;
    }
    /* .portfolio-3-area.custom-portfolio-css .single-portfolio .portfolio-content{
      padding: 35px 33px !important;
    } */
  }
  
  @media (min-width: 1069px) and (max-width: 1235px) {
    /* .portfolio-3-area.custom-portfolio-css .single-portfolio .portfolio-content{
      padding: 15px 30px !important;
    } */
  
    .portfolio-3-area[_ngcontent-yre-c239]
      .single-portfolio[_ngcontent-yre-c239]
      .portfolio-content[_ngcontent-yre-c239]
      a[_ngcontent-yre-c239] {
      background: #072f5f;
      border-radius: 6px;
      margin-top: 30px !important;
    }
  }
  @media (min-width: 1236px) and (max-width: 1600px) {
    .portfolio-3-area.custom-portfolio-css .single-portfolio .portfolio-content {
      padding: 15px 0px !important;
    }
  }
  
  /*  */
  @media (max-width: 767.98px) {
    div.services-card-ind {
      max-width: 370px !important;
    }
    .widthcentre {
      max-width: 350px !important;
    }
  
    .col,
    .col-1,
    .col-10,
    .col-11,
    .col-12,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-auto,
    .col-lg,
    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-auto,
    .col-md,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-auto,
    .col-sm,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-auto,
    .col-xl,
    .col-xl-1,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-auto {
      position: relative;
      width: unset !important;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  
  @media (min-width: 1199px) {
    span {
      font-size: 12px;
    }
    .uaeread {
      height: 149px !important;
    }
  
    /* .indread{
    height: 170px !important;
   } */
  
    .portfolio-3-area.custom-portfolio-css .single-portfolio {
      box-shadow: 0px 8px 16px 0px rgba(60, 110, 203, 0.1);
      height: 350px !important;
      border-bottom: solid 2px #fff;
      position: relative;
      display: inline-block;
    }
  }
  
  @media (max-width: 992px) {
    .services-card {
      width: 240px !important;
      display: flex;
      justify-content: center;
    }
    .indread {
      margin-top: 0px !important;
    }
  
    /* .indCardTwo{
      margin-top: 30px ;
     }
  
     .indCardThree{
      margin-top: 46px ;
     }
  
     .indCardFour{
      margin-top: 46px ;
     } */
  }
  
  @media (max-width: 991px) {
    /* Hide image and adjust form column */
    .contact-row {
      flex-wrap: wrap-reverse;
    }
  
    .contact-row .col-lg-6:first-child {
      display: none;
    }
  
    .contact-row .col-lg-6:last-child {
      flex: 1;
    }
  
    /* Adjust input fields */
    .col-lg-5 {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  
    /* Adjust message box */
    .col-lg-10 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    /* Adjust "Send Message" button */
  
    .services-card {
      width: 300px !important;
      display: flex;
      justify-content: center;
    }
  }
  
  @media (max-width: 991px) {
    .col-lg-3[_ngcontent-gqe-c239] {
      flex: 0 0 100%;
  
      text-align: center;
      margin-right: 20px;
    }
  }
  
  @media (min-width: 1199px) and (max-width: 1235px) {
    .firstread {
      margin-top: 13px !important;
    }
  }
  
  @media (min-width: 993px) {
    .indread {
      margin-top: 0px !important;
    }
  
    .indCardTwo {
      height: 180px !important;
    }
  
    .indCardThree {
      height: 180px !important;
    }
  
    .indCardFour {
      height: 180px !important;
    }
    .indCardFive {
      height: 180px !important;
    }
  }
  
  @media (min-width: 1088px) and (max-width: 1200px) {
    .indread {
      height: 150px !important;
    }
    .indCardTwo {
      height: 100px !important;
      margin-top: 0px !important;
    }
  
    .indCardThree {
      height: 150px !important;
    }
  
    .indCardFour {
      height: 150px !important;
    }
    .indCardFive {
      height: 150px !important;
    }
  }
  @media (max-width: 1200px) {
    .portfolio-3-area .single-portfolio .portfolio-content a {
      background: #072f5f;
      border-radius: 6px;
      margin-top: 31px;
    }
  }
  @media (min-width: 1200px) {
    .services-card {
      width: 235px !important;
    }
    .indCardMargin {
      margin-right: 40px;
    }
  
    .indread {
      height: 0px !important;
    }
  
    .indCardTwo {
      height: 100px !important;
      margin-top: 0px !important;
    }
  
    .indCardThree {
      height: 150px !important;
    }
  
    .indCardFour {
      height: 150px !important;
    }
    .indCardFive {
      height: 150px !important;
    }
  
    .portfolio-3-area[_ngcontent-ovi-c239]
      .single-portfolio[_ngcontent-ovi-c239]
      .portfolio-content[_ngcontent-ovi-c239]
      a[_ngcontent-ovi-c239] {
      background: #072f5f !important;
      border-radius: 6px !important;
      margin-top: 36px !important;
    }
  }
  
  /* arrow z-index */
  .fal.fa-arrow-right {
    z-index: 1;
  }
  
  @media (min-width: 992px) and(max-width:1200px) {
    .portfolio-3-area[_ngcontent-wmo-c239]
      .single-portfolio[_ngcontent-wmo-c239]
      .portfolio-content[_ngcontent-wmo-c239]
      a[_ngcontent-wmo-c239] {
      background: #072f5f;
      border-radius: 6px;
      margin-top: 30px !important;
    }
  
    .firstread {
      margin-top: 30px !important;
    }
    .secondread {
      margin-top: 30px !important;
    }
  }
  
  @media (min-width: 1601px) {
    .firstread {
      margin-top: 12px !important;
    }
    .secondread {
    }
  }
  
  @media (max-width: 992px) {
    .portfolio-3-area.custom-portfolio-css .single-portfolio .portfolio-content {
      padding: 15px 10px !important;
      height: 162px !important;
    }
  
    .indCardTwo[_ngcontent-klv-c239] {
      margin-top: 0px !important;
    }
  }
  
  /* fomt sizes for mobile view */
  
  @media (max-width: 567px) {
    h3 {
      font-size: 17px;
    }
  
    .testword {
      font-size: 22px !important;
    }
    .contacth3 {
      font-size: 22px !important;
    }
    .producth3 {
      font-size: 20px !important;
      padding: 5px;
    }
    h2 {
      font-size: 22px;
    }
  }
  
  /* Custom CSS for shadow and equal height */
  .custom-card {
    box-shadow: 0 7px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Custom CSS for vertical alignment of "Read more" button */
  .read-more-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .read-more-btn {
    margin-top: auto;
    background: #072f5f;
    border-radius: 6px;
    color: white;
    font-size: 14px;
    padding: 0.5rem;
  }
  
  /* Increase the height of the cards */
  
  .custom-card .service-title {
    padding: 5px;
    margin-bottom: 1rem;
  }
  
  .inddiv {
    height: 130px;
  }
  /* For larger screens, increase the height of the cards */
  
  .custom-card {
    height: 308px;
    max-width: 190px;
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .custom-card {
      height: 400px !important;
      max-width: 308px !important;
    }
  }
  
  @media (max-width: 992px) {
    .custom-card {
      height: 408px;
      max-width: 320px;
    }
  }
  
  @media (min-width: 1200px) {
    .custom-card {
      height: 350px;
      max-width: 240px;
    }
  
    .carddiv {
      max-width: 20% !important;
    }
  
    .col-lg-2 {
      flex: 0 0 20%; /* You can adjust the percentage as per your requirement */
    }
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .col-lg-2 {
      flex: 0 0 20%; /* You can adjust the percentage as per your requirement */
    }
    .carddiv {
      max-width: 20% !important;
    }
    .custom-card {
      height: 320px;
      max-width: 240px;
    }
  }
  
  .carousel-control-next {
    right: -50%;
  }
  .carousel-control-prev {
    left: -50%;
  }
  
  /* CSS for the sliding blue line */
  .blue-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #072f5f;
    transform: scaleX(0); /* Initially hidden */
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }
  
  /* CSS for the card container */
  .custom-card {
    position: relative;
    cursor: pointer;
    background-color: white;
  }
  
  /* Hover state for the card container */
  .custom-card :hover .blue-line {
    transform: scaleX(1); /* Sliding effect on hover */
  }
  /* Ensure the blue line stays within the image div */
  .custom-card {
    overflow: hidden;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-us-area .contact-overlay .contact-details {
      display: block !important;
      padding: 1rem;
    }
  }
  
  
  @media screen and (max-width:990px){
    .img-contact-us{
      display: none !important;
    }
  }
  