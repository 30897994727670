/* h1 {
    font-size: 48px !important;
    border-bottom: 3px solid white;
    display: inline-block;
  } */
  
  .line-managers img{
    width: 10rem;
  }
  
  
  p{
    font-size: 16px !important;
    font-weight: 500;
    text-align: justify;
  }
  
  h4 {
    font-size: 18px;
  }
  
  h3 {
    font-size: 28px;
  }
  
  span {
    font-size: 16px !important;
  }
  
  .carrd {
    /* height:'295px'; */
    transition: box-shadow 0.3s;
  }
  
  .carrd:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
  
  .teamTital {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .bottom {
    box-shadow: 0px 20px 15px -20px #b9b9b9;
  }
  
  .blue-shade {
    background-color: #052137;
    opacity: 0.8;
  }
  
  @media (max-width: 574px){
    p{font-size: 14px !important;}
    .marginTop{
      margin-top: 80px !important;
    }
  }
  
  @media (min-width: 576px){
    .marginTop{
      margin-top: 120px !important;
    }
    .imgSpace{
  
    }
  }
  
  @media (max-width: 768px) {
    .row.justify-content-center {
      justify-content: center !important;
    }
  
  
  }
  
  @media (min-width: 968px) {
    .marginTop{
      margin-top: 170px !important;
    }
  }

  /* product */

@media (max-width: 768px) {
  .flex-container {
    display: flex;
    flex-direction: column;
  }
  
  .order-1 {
    order: 1;
  }
  
  .order-2 {
    order: 2;
  }
}

