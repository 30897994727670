.footer-area .widget-item-2 .footer-list ul li, .footer-area.footer-area-2 .footer-overlay .widget-item-2.widget-item-3 ul li {
    color: #fff;
}

.icon-bar {
  position: fixed;
  bottom: 120px;
  -webkit-transform: translateY(-60%);
  -ms-transform: translateY(-60%);
  transform: translateY(-60%);
  right: 1.8rem;
  z-index:1000;
  background: blu;
}
