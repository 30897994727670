.flip-card-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1600px;
}
.unique-paragraph{
  padding-left: 60px;
  padding-right: 60px;
}
.flip-card {
  background-color: transparent;
 width: auto;
  height: 280px; /* Ensure the height of the card matches the image height */
  overflow: hidden;
  perspective: 1000px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.flip-card img {
  width: auto;
  height: 170px;
  object-fit: cover;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.8s cubic-bezier(0.4, 0.2, 0.2, 1);
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  border: 1px solid #ccc;
  /* border-radius: 30px; */
  padding: 20px;
}
.flip-card-front h2 {
  font-size: 18px;
  margin: 10px 0;
  margin-top: 28px;
  text-align: center; /* Center the text horizontally */
}


.flip-card-front {
  background-color: #f0f8ff;
  color: black;
}

.flip-card-back {
  background-size: cover;
  background-position: center;
  color: white;
  transform: rotateX(180deg);
  position: relative;
}

.flip-card-back::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); 
  /* border-radius: 10px; */
  z-index: 1;
}

.flip-card-back h2 {
  font-size: 0; 
}

.flip-card-back p {
  font-size: 15px; /* Adjust the description font size on the back card */
  margin: 10px 0;
  color: white; /* Change description text color to white */
  z-index: 2; /* Ensure the text is above the overlay */
  text-align: left; /* Center the text horizontally */
}

.read-more-btn {
  background-color: #072f5f;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  z-index: 2; 
}

.read-more-btn:hover {
  background-color: #fff;
  color: black ;
}

.flip-card-front h2 {
  font-size: 18px; 
  margin: 10px 0;
  margin-top: 28px;
}
/* FlipCard.css */

.buttonUnique {
  background-color: #072f5f !important; 
  color: white; /* Text color */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px 20px; /* Padding for the button */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.buttonUnique:hover {
  background-color: #000 !important; 
}

.flip-card.reverse-order .flip-card-back {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.flip-card.reverse-order .flip-card-back img {
  order: -1; /* This moves the image to the top */
}

.flip-card.reverse-order .flip-card-back h2 {
  margin-top: 20px;
}


/* Custom CSS classes for each card */
.card-1 .flip-card-front { background-color: #f0f8ff !important ;}
.card-2 .flip-card-front { background-color: #f0f8ff !important ;}
.card-3 .flip-card-front { background-color: #f0f8ff !important ; }
.card-4 .flip-card-front { background-color: #f0f8ff !important ; }
